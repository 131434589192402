*{
    list-style-type: none;
    font-family: 'Montserrat', 'sans-serif';
}

@media only screen and (min-width: 750px) {
    #song {
        width: 50%;
    }

    #search-container {
        width: 84%;
    }

    #filter-input {
        width: 15%;
    }
}

@media only screen and (max-width: 750px) {
    #song {
        width: 100%;
    }

    #search-container {
        width: 72%;
    }

    #filter-input {
        width: 26%;
    }
}

#logo{
    width: 2.3em;
    height: 2.3em;
    background-color: transparent;
    transition: 0.5s;
}

#logo:hover {
    transform: rotateZ(360deg);
}

#header-link {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #35b233;
}

#header-title, #header-subtitle {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

#header-title {
    color: #35b233;
    font-size: 1.5em;
    margin-top: 0.3em;
    margin-left: 0.2em;
    margin-bottom: -0.3em;
}

#header-subtitle {
    color: white;
    font-size: 0.8em;
    margin-left: 1em;
}

#header{
    background-color: black;
    color: #35b233;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1;
}

#header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1.5em;
}

.link {
    color: #35b233;

}
.tablerow:hover {
    color: #35b233;
}

.tablerow:hover {
    font-size: 115%;
    text-decoration: none;
}

#toprow {
    background-color: #2c8f43;
    color: white;
    height: 2.25em;
    font-weight: bold;
}

.toprowcell {
    transition: 0.2s;
    height: 100%;
    vertical-align: middle;
}

#toprow, .tablerow {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.2s;
}

.tablerow {
    color: #35b235;
    border-bottom: 0.05em solid gray;
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
}

.songtitle, #toprow-left {
    padding-left: 5px;
    width: 80%;
    padding-top: 8px;
}

.songnumber, #toprow-right {
    padding-top: 8px;
    width: 20%;
    text-align: right;
    padding-right: 5px;
}

#indent {
    text-indent: 5em;
}

#song, #main {
    margin-top: 3em;
}

#songlist {
    width: 100%;
}

#songlist, #song {
    margin-left: auto;
    margin-right: auto;
}

#song {
    margin-left: auto;
    margin-right: auto;
}

#search {
    width: 100%;
    border: 0.2em solid lightgray;
    border-radius: 5px;
    height: 2.5em;
    margin-bottom: 1em;
}

#search-placeholder {
    position: absolute;
    top:  0.6em;
    left: 0.8em;
    transition: ease-in-out 0.2s;
    pointer-events: none;
}

#search:focus, #filter-input:focus {
    border: 0.2em solid #35b233;
    outline: none;
}

#search:focus ~ #search-placeholder, #search:not(:focus):valid ~ #search-placeholder{
    top: -0.6em;
    left: 0.8em;
    background-color: white;
    padding: 0 5px;
}

#search-container {
    position: relative;
}

#main {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    align-content: center;
    justify-content: center;
}

.toprowcell:hover {
    background-color: #008000;
    cursor: pointer;
}

#banner {
    background-image: url("components/banner/tausta-smaller.jpeg");
    width: 100%;
    height: 17em;
}

#banner-container {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.5);
    z-index: 0;
    align-items: center;
}

#banner-title {
    padding-top: 3.5em;
    margin-left: 10%;
    color: white;
    font-size: 3em;
    z-index: 4;
    opacity: 1;
    font-size: 46px;
    font-weight: bold;
    font-family: 'Poppins',sans-serif;
}

#filter-input {
    border: 0.2em solid lightgray;
    border-radius: 5px;
    height: 2.5em;
}

#filter-search-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');

